import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Spinner,
} from "reactstrap";
import { RegexConfig } from "../../../config/RegexConfig";
import DeviceMetaData from "../../../device-metadata";
import { showToast } from "../../../helper-methods";
import {
  deepClone,
  errorHandler,
  setThemeColor,
  updateCompanyName,
  updateFavIcon,
} from "../../../helper-methods/index";
import {
  assignNewDeviceId,
  checkAgentGuestUser,
  customWebsiteDetail,
  getIpData,
  login,
  socialLogin,
} from "../../../http/http-calls";
import { addUserData } from "../../../redux/actions/user-data";
import jwt_decode from "jwt-decode";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import OneSignalHelper from "../../../onesignal-helper/onesignalHelper";
import AppleLoginComponent from "../components/AppleLoginComponent";
import GoogleLoginComponent from "../components/GoogleLoginComponent";
import PublicSideCarousel from "../components/PublicCarousel/PublicSideCarousel";
import TermsAndConditionModal from "../components/termsAndConditionModal";
import EmailVerificationModal from "./email-verification-modal";
import Gleap from "gleap";
import { APP_URL } from "../../../config";
import { createBrowserHistory } from "history";
import { history } from "../../../App";
import Swal from "sweetalert2";

export const historyWithForceRefresh = createBrowserHistory({
  forceRefresh: true,
});

class Login extends React.Component {
  state = {
    formFields: {
      handle: {
        value: "",
        error: null,
        isDirty: false,
      },
      password: {
        value: "",
        error: null,
        isDirty: false,
      },
      isTermsAndConditionsAccepted: {
        value: false,
        error: null,
        isDirty: false,
      },
    },
    companyDetail: {},
    isShowPassword: false,
    emailVerificationModal: {
      isOpen: false,
      data: null,
    },
    termsAndConditionModal: {
      isOpen: false,
      data: null,
    },
  };

  _extractAgentId(url) {
    const match = url.match(/[?&]agentid=([^&]+)/);
    return match && match[1];
  }

  _checkAgentGuestUser = async () => {
    let agentId = this._extractAgentId(history.location.search);
    try {
      let response = await checkAgentGuestUser(agentId);
      if (response?.isGuest) {
        console.log("age", agentId);
        historyWithForceRefresh.push(`/nna-signup/${agentId}`);
      } else {
        history.push("/login");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  componentDidMount() {
    // Hide Gleap in this page
    Gleap.showFeedbackButton(false);

    document.body.classList.toggle("login-page");
    this._customWebsiteDetail();

    if (this.props.history.location.search.includes("token")) {
      // get token from URL
      let path = this.props.history.location.search?.split("=")[1];
      this._loginFromToken(path);
    } else {
      let agentId = this._extractAgentId(history.location.search);
      if (agentId) {
        this._checkAgentGuestUser();
      }
    }

    document.title = "CloseWise Login | Access Your Notary Business Dashboard";
    const metaDescription = document.querySelector("meta[name='description']");

    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Access your CloseWise account to effortlessly manage your notary business. Log in to streamline workflows with tools for websites, CRM, and more"
      );
    } else {
      const newMetaDescription = document.createElement("meta");
      newMetaDescription.name = "description";
      newMetaDescription.content =
        "Access your CloseWise account to effortlessly manage your notary business. Log in to streamline workflows with tools for websites, CRM, and more";
      document.head.appendChild(newMetaDescription);
    }
  }

  // if someone has token in URL
  // Don't need to call login API just decode the token and save to Redux
  _loginFromToken = async (token) => {
    this.props.showLoader("Loading...");
    var decoded = jwt_decode(token);
    console.log("De", decoded);
    const userData = {
      token: token,
      type: decoded.type.toLowerCase(),
      handle: decoded.email,
      user: { ...decoded },
    };

    Gleap.identify(
      decoded?._id,
      {
        name: decoded.name?.full,
        email: decoded.email,
        companyName: decoded.companyName,
        stripeId: decoded.stripeId,
        customerStripeId: decoded.customerStripeId,
        type: decoded.type,
      },
      decoded?.gleapHash
    );

    this.props.addUserData(userData);
    try {
      let detail = await this._getDeviceDetails();
      localStorage.setItem("deviceId", detail?.deviceId);
      let res = await assignNewDeviceId({
        deviceId: detail?.deviceId,
      });
      res?.message && showToast(res.message, "success");
      this.props.hideLoader();
      this.props.history.push("/agent/dashboard");
    } catch (error) {
      this.props.hideLoader();
      errorHandler(error);
    }
  };

  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  // this function fetch website data like: Theame
  _customWebsiteDetail = () => {
    let payload = {
      site:
        window.location.hostname !== "localhost"
          ? window.location.host
          : "devapp.closewise.com",
    };

    customWebsiteDetail(payload)
      .then((res) => {
        this.setState({ companyDetail: res?.detail }, () => {
          if (
            !this.state.companyDetail?.whiteLabelSetting?.isDefaultUiSelected
          ) {
            let whiteLabelSetting =
              this.state.companyDetail?.whiteLabelSetting?.customizedUiSetting;

            setThemeColor(whiteLabelSetting);
          } else {
            let whiteLabelSetting =
              this.state.companyDetail?.whiteLabelSetting?.defaultUiSetting;

            // let matchingColorScheme = _findMatchingColorScheme(
            //   whiteLabelSetting,
            //   colorsUI
            // );
            setThemeColor(whiteLabelSetting);
          }
          if (this.state?.companyDetail) {
            updateCompanyName(this.state.companyDetail);
            updateFavIcon(this.state.companyDetail?.whiteLabelSetting);
          }
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  // get device details from ONE SIGNAL AND send get IP address
  _getDeviceDetails = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const deviceDetails = {};
        deviceDetails["name"] =
          DeviceMetaData.getBrowser() + " on " + DeviceMetaData.getOs();
        deviceDetails["ipAddress"] = (await getIpData())["ip"];
        // Get device id
        // First try to get from one signal
        let onesignalDeviceId = null;
        onesignalDeviceId = await OneSignalHelper.getDeviceId();
        if (onesignalDeviceId) {
          deviceDetails["allowNotification"] = true;
          deviceDetails["deviceId"] = onesignalDeviceId;
        } else {
          deviceDetails["allowNotification"] = false;
          deviceDetails["deviceId"] = DeviceMetaData.getDeviceId();
        }

        resolve(deviceDetails);
      } catch (error) {
        reject(error);
      }
    });
  };

  _resetFormField = () => {
    this.setState({
      formFields: {
        handle: {
          value: "",
          error: null,
          isDirty: false,
        },
        password: {
          value: "",
          error: null,
          isDirty: false,
        },
        isTermsAndConditionsAccepted: {
          value: false,
          error: null,
          isDirty: false,
        },
      },
    });
  };

  _markAsDirty = (fieldName) => {
    const { formFields } = this.state;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
    this._validateForm();
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty) {
          switch (e) {
            case "handle": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.password.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error =
                    "*Minimum eight characters, at least one letter, one number and one special character";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (type, value) => {
    const { formFields } = this.state;
    formFields[type].value = value;
    formFields[type].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _userLogin = async (e) => {
    if (e) e.preventDefault();

    await this._markAllFieldDirty();
    const isFormValid = await this._validateForm();

    if (isFormValid) {
      console.log(this.props);
      this.props.showLoader("Loading...");

      const { formFields } = deepClone(this.state);
      console.log("first,", this.state.formFields);
      let detail = await this._getDeviceDetails();

      login({
        deviceDetails: {
          deviceId: detail?.deviceId,
          ipAddress: detail?.ipAddress,
          name: detail?.name,
        },
        handle: formFields.handle.value,
        password: formFields.password.value,
        isTermsAndConditionsAccepted: formFields.isTermsAndConditionsAccepted
          .value
          ? formFields.isTermsAndConditionsAccepted.value
          : undefined,
        site:
          window.location.hostname !== "localhost"
            ? window.location.host
            : // : "shank.closewise.com",
              // "customizedwl.logic-square.com",
              // "app.closewise.com",
              // "https://app.closewisenotary.com",
              // "orders.expertnotariesandservices.com",
              // "whitelabel.closewise.com",
              // "devapp.closewise.com",
              "orders.legalforceusa.com",
        // "maya.logic-square.com"
      })
        .then(async (res) => {
          if (!res.hasOwnProperty("token")) {
            if (
              res?.isTermsAndConditionsAccepted !== undefined &&
              !res?.isTermsAndConditionsAccepted
            ) {
              this._termsAndConditionModalToggle(true);
              this.props.hideLoader();
              return;
            }
            if (
              String(res?.isEmailVerified) &&
              String(res?.isEmailVerified) !== "undefined" &&
              !res?.isEmailVerified
            ) {
              // console.log("do email verification process!");
              const data = {
                handle: formFields.handle.value,
              };
              this._emailVerificationModalToggle(true, data);
              this.props.hideLoader();
              return;
            }
          }

          // if user is a agent
          if (res.hasOwnProperty("agentId")) {
            this.props.history.push(`/nna-signup/${res.agentId}`);
            this.props.hideLoader();
          }

          if (res.token) {
            var decoded = jwt_decode(res.token);
            const userData = {
              token: res.token,
              type: decoded.type.toLowerCase(),
              handle: res.handle,
              user: { ...decoded },
            };

            Gleap.identify(
              decoded?._id,
              {
                name: decoded.name?.full,
                email: decoded.email,
                companyName: decoded.companyName,
                stripeId: decoded.stripeId,
                customerStripeId: decoded.customerStripeId,
                type: decoded.type,
              },
              decoded?.gleapHash
            );

            if (
              decoded.type?.toLowerCase() === "agent" ||
              decoded.type?.toLowerCase() === "signingcompany" ||
              decoded.type?.toLowerCase() === "client"
            ) {
              if (
                window.location.hostname !== "localhost" &&
                decoded.type?.toLowerCase() === "agent" &&
                decoded?.siteOwnerDetail?._id !== undefined
              ) {
                window.location.href = `${APP_URL}/login?token=${res.token}`;
                this.props.hideLoader();
              } else {
                this._resetFormField();
                this.props.addUserData(userData);
                try {
                  localStorage.setItem("deviceId", detail?.deviceId);
                  let res = await assignNewDeviceId({
                    deviceId: detail?.deviceId,
                  });
                  res?.message && showToast(res.message, "success");
                } catch (error) {
                  errorHandler(error);
                }
                this.props.hideLoader();
              }
              if (
                decoded.type?.toLowerCase() === "signingcompany" &&
                decoded?.isAssistant
              ) {
                this.props.history.push("/signingcompany/profile"); // team members of signing company by default visiting to route: /signingcompany/profile after login
              } else {
                if (
                  JSON.parse(localStorage.getItem("root")) &&
                  JSON.parse(localStorage.getItem("root")).userData !== "{}"
                ) {
                  let path = this.props.history.location.search?.split("=")[1];

                  if (path === undefined) {
                    //check 1st time login or not
                    // if (
                    //   userData?.type === "agent" &&
                    //   decoded.isSubscribed &&
                    //   decoded?._subscription?.isExpired
                    //   // &&!userData?.user?.isWelcomeVideo
                    // ) {
                    //   this.props.history.push("/agent/subscription");
                    // }
                    if (userData?.type === "agent") {
                      const tabId = 1;
                      const query = new URLSearchParams({ tabId }).toString();
                      this.props.history.push(
                        `/${userData.type}/profile?${query}`
                      );
                    } else if (
                      userData?.type === "signingcompany" &&
                      decoded.isSubscribed &&
                      decoded?._subscription?.isExpired
                      // userData?.user?.isTitleCompany
                    ) {
                      this.props.history.push("/signingcompany/subscription");
                    } else {
                      this.props.history.push(
                        `/${userData.type}/closing-dashboard`
                      );
                    }
                  } else {
                    this.props.history.push(path);
                  }
                } else {
                  this.props.history.push(`/${userData.type}`);
                }
              }
            } else {
              showToast("Unauthorized", "error");
            }
          }
          document.title = "CloseWise | Access Your Notary Business Dashboard";
        })
        .catch((error) => {
          console.log("gh", error);
          errorHandler(error);
          this.props.hideLoader();
        });
    }
  };

  _emailVerificationModalToggle = (isOpen = false, data = {}) => {
    const { emailVerificationModal } = this.state;

    emailVerificationModal.isOpen = isOpen;
    emailVerificationModal.data = data;

    this.setState({ emailVerificationModal });
  };

  _termsAndConditionModalToggle = (isOpen = false, data = {}) => {
    const { termsAndConditionModal } = this.state;

    termsAndConditionModal.isOpen = isOpen;
    termsAndConditionModal.data = data;

    this.setState({ termsAndConditionModal });
  };

  _showPasswordToggle = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  _socialLogin = async (platform, accessToken, name) => {
    this.props.showLoader(`Login through ${platform}...`);
    const { formFields } = deepClone(this.state);
    let detail = await this._getDeviceDetails();

    const payload = {
      deviceDetails: {
        deviceId: detail?.deviceId,
        ipAddress: detail?.ipAddress,
        name: detail?.name,
      },
      domain: platform,
      accessToken,
      mode: "web",
      isTermsAndConditionsAccepted: formFields?.isTermsAndConditionsAccepted
        ?.value
        ? formFields?.isTermsAndConditionsAccepted?.value
        : undefined,
      site:
        window.location.hostname !== "localhost"
          ? window.location.host
          : "customizedwl.logic-square.com",
    };

    if (name) payload.name = accessToken.name;

    socialLogin(payload)
      .then(async (res) => {
        if (res.hasOwnProperty("agentId")) {
          this.props.history.push(`/nna-signup/${res.agentId}`);
          this.props.hideLoader();
          return;
        }

        var decoded = jwt_decode(res.token);

        if (
          decoded.type?.toLowerCase() === "agent" ||
          decoded.type?.toLowerCase() === "signingcompany" ||
          decoded.type?.toLowerCase() === "client"
        ) {
          const userData = {
            token: res.token,
            type: decoded.type.toLowerCase(),
            handle: res.handle,
            user: { ...decoded },
          };
          this._resetFormField();
          this.props.addUserData(userData);
          try {
            // let detail = await this._getDeviceDetails();
            localStorage.setItem("deviceId", detail?.deviceId);
            let res = await assignNewDeviceId({ deviceId: detail?.deviceId });
            res?.message && showToast(res.message, "success");
          } catch (error) {
            errorHandler(error);
          }
          this.props.hideLoader();

          if (
            JSON.parse(localStorage.getItem("root")) &&
            JSON.parse(localStorage.getItem("root")).userData !== "{}"
          ) {
            // let path = this.props.history.location.search?.split("=")[1];
            // this.props.history.push(path);
            window.location.href = `/${userData.type}`;
          } else {
            this.props.history.push(`/${userData.type}`);
          }
        } else {
          showToast("Unauthorized", "error");
        }
      })
      .catch((error) => {
        this.props.hideLoader();
        errorHandler(error);
        // window.location.href = `/login`;

        Swal.fire({
          title: "Error!",
          text:
            error?.reason || "An unexpected error occurred. Please try again.",
          icon: "error",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            window.location.href = "/login";
          }
        });
      });
  };

  _termLogin = ({ isTermsAndConditionsAccepted }) => {
    // console.log(isTermsAndConditionsAccepted)
    const { formFields } = this.state;
    formFields["isTermsAndConditionsAccepted"].value =
      isTermsAndConditionsAccepted;
    this.setState(
      {
        formFields,
      },
      () => {
        this._userLogin();
      }
    );
  };

  render() {
    const {
      formFields,
      isShowPassword,
      termsAndConditionModal,
      emailVerificationModal,
      companyDetail,
    } = this.state;
    return (
      <>
        <div className="loginWrapper">
          {/* left */}
          <div
            className={`loginLeft ${
              window.location.host !== "app.closewise.com" ? "whiteLabel" : ""
            }`}
          >
            {window.location.host === "app.closewise.com" ? (
              <PublicSideCarousel companyDetail={companyDetail} />
            ) : window.location.host === "app.closewise.com" ? (
              <img
                src={require("../../../assets/img/logBigIcon.png")}
                alt="logo big"
                className="bigLogo"
              />
            ) : companyDetail.logo ? (
              <img
                src={companyDetail.logo}
                alt="logo big"
                className="bigLogo"
              />
            ) : (
              <Spinner />
            )}
          </div>
          {/* right */}
          <div className="loginRight">
            {window.location.host === "app.closewise.com" && (
              <img
                src={
                  companyDetail.logo
                    ? companyDetail.logo
                    : require("../../../assets/img/logBigIcon.png")
                }
                alt="logo big"
                className="bigLogo"
              />
            )}
            <div className="FormWrap">
              {/* <h1>Hello Again!</h1> */}
              <h1>Login to Your Account</h1>
              <p className="mb-4">Please enter your email and password</p>
              <Form onSubmit={this._userLogin} spellCheck="false">
                <FormGroup className="floatingLabel">
                  <Input
                    type="email"
                    value={formFields.handle.value}
                    onChange={(e) =>
                      this._onChangeFormField("handle", e.target.value)
                    }
                    onBlur={() => this._markAsDirty("handle")}
                    placeholder=" "
                  />
                  <Label>Email Address</Label>

                  {formFields.handle.error && (
                    <div className="validation-error">
                      {formFields.handle.error}
                    </div>
                  )}
                </FormGroup>
                <FormGroup className="floatingLabel">
                  <InputGroup>
                    <Input
                      type={isShowPassword ? "text" : "password"}
                      value={formFields.password.value}
                      onChange={(e) =>
                        this._onChangeFormField("password", e.target.value)
                      }
                      onBlur={() => this._markAsDirty("password")}
                      placeholder=" "
                    />
                    <Label>Password</Label>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <img
                          src={require(`../../../assets/img/${
                            isShowPassword ? "eyeIcon.svg" : "eyeIconSlash.svg"
                          }`)}
                          onClick={this._showPasswordToggle}
                          alt="eye icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>

                  {formFields.password.error && (
                    <div className="validation-error">
                      {formFields.password.error}
                    </div>
                  )}
                </FormGroup>

                <div className="d-flex justify-content-end mb-3">
                  <Link to="/forgot-password" className="text-muted">
                    Forgot Password?
                  </Link>
                </div>

                <Button color="primary" className="w-100 d-block" type="submit">
                  Login
                </Button>
              </Form>

              <div className="orContinue">
                <span>Or Continue With</span>
              </div>

              <div className={`socialLogin`}>
                <GoogleLoginComponent
                  className="google"
                  onSuccess={(res) =>
                    this._socialLogin("google", res.accessToken)
                  }
                />
                <AppleLoginComponent
                  className="apple"
                  onSuccess={(res) =>
                    this._socialLogin("apple", res.accessToken, res.name)
                  }
                />
              </div>
              <p style={{ fontWeight: "500", textAlign: "center" }}>
                Don't have an account? <Link to="/signup">Register now</Link>
              </p>
            </div>
          </div>
        </div>

        <EmailVerificationModal
          isOpen={emailVerificationModal?.isOpen}
          data={emailVerificationModal?.data}
          toggle={(isOpen, data) =>
            this._emailVerificationModalToggle(isOpen, data)
          }
        />
        <TermsAndConditionModal
          isOpen={termsAndConditionModal?.isOpen}
          data={termsAndConditionModal?.data}
          toggle={(isOpen, data) =>
            this._termsAndConditionModalToggle(isOpen, data)
          }
          termLogin={(termAccepted) => this._termLogin(termAccepted)}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUserData: (userData) => dispatch(addUserData(userData)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(Login);
