import React, { Component, Suspense, lazy } from "react";
import StarRatings from "react-star-ratings";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Label,
  Row,
} from "reactstrap";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  capitalize,
  checkPermission,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  getPhoneNumberFromBrackets,
  openUrlOnNewTab,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
// import {
//   googlePlaceDetails,
//   googlePlaceSearch,
// } from "../../../helper-methods/googleService";
import {
  ClientGetSigningCompanyCustomOrderFormId,
  activityLogClientClosing,
  createInvoiceClient,
  getAllLenderClients,
  getAllSigningCompanyForClient,
  getClientSettings,
  getClosingDetailsById,
  getTrackingInfoById,
  updateClosingDetailsById,
} from "../../../http/http-calls";
import OrderDetailsInstructions from "./OrderDetailsInstructions";
import SignerAppointment from "../components/common/signerAppointment";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import makeAnimated from "react-select/animated";
import { BASE_URL } from "../../../config";
import { store as REDUX_STORE } from "../../../redux/store";
import {
  openTrackingDetails,
  TRACKING_URL,
} from "../../../config/trackingConfig";

// eslint-disable-next-line no-unused-vars
const animatedComponents = makeAnimated();

const SecureDocumentCardComponent = lazy(() =>
  import("../components/SecureDocumentCardComponent")
);
const ChatLogCardComponent = lazy(() =>
  import("../components/ChatLogCardComponent")
);
const ClientActivityLog = lazy(() => import("../components/ClientActivityLog"));
const SignerAvailabilityModal = lazy(() =>
  import("../components/signer-availability-modal")
);
const EditOrderDetailsModal = lazy(() =>
  import("../components/Modals/EditOrderDetailsModal")
);
const defaultValue = {
  value: "",
  isDirty: false,
  isValidate: true,
};

const defaultField = {
  value: "",
  isDirty: false,
  isValidate: false,
};
class OrderDetails extends Component {
  state = {
    closingDetails: null,
    formFields: {
      borrower: [
        {
          _id: {
            value: "",
          },
          borrowerName: {
            ...defaultValue,
          },
          email: {
            ...defaultValue,
          },
          workPhone: {
            ...defaultValue,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            ...defaultValue,
          },
          languageTypeOther: {
            ...defaultValue,
          },
          timeZone: {
            ...defaultValue,
          },
        },
      ],

      additionalSigner: {
        ...defaultValue,
      },
      borrowerSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      closingSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      line1: {
        ...defaultValue,
      },
      line2: {
        ...defaultField,
      },
      city: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      state: {
        ...defaultValue,
      },
      zip: {
        ...defaultValue,
      },
      loanOfficer: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      fileNumber: {
        ...defaultValue,
      },
      titleCompany: {
        ...defaultValue,
      },
      lender: {
        ...defaultValue,
      },
      personOfContactName: {
        ...defaultValue,
      },
      personOfContactEmail: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        ...defaultValue,
      },
      loanTypeOther: {
        ...defaultValue,
      },
      signingCompanyId: {
        ...defaultValue,
      },
      signingCompanyFee: {
        ...defaultValue,
      },
      lenderClientId: {
        ...defaultValue,
      },
      appointmentDate: {
        ...defaultValue,
      },
      closingAddressLine1: {
        ...defaultValue,
      },
      closingAddressLine2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      closingAddressCity: {
        ...defaultValue,
      },
      closingAddressState: {
        ...defaultValue,
      },
      closingAddressZip: {
        ...defaultValue,
      },
      files: {
        ...defaultValue,
      },
      notes: {
        ...defaultField,
      },
      instructionId: {
        ...defaultField,
      },
      instructions: {
        ...defaultField,
      },
      internalNotes: {
        ...defaultField,
      },
      companyNotes: {
        ...defaultField,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    editing: {
      appointment: false,
      orderDetail: false,
      instructions: false,
    },
    appointmentMapType: "google",
    lenderClients: [],
    signingCompanies: [],
    signingInstruction: [],
    searchResult: [],
    activityLog: [],
    activityLogPage: {
      skip: 0,
      limit: 5,
      totalCount: 0,
    },
    loading: {
      dataLoading: false,
      statusChangeLoading: false,
      fileUploadLoading: false,
      expensesLoading: false,
      deleteExpensesLoading: false,
      cardUpdateLoading: false,
    },
    show: false,
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    editOrderDetailsModal: {
      isOpen: false,
      data: null,
    },
    isOpenCollapse: true,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = this.state;
    loading[loaderName] = value;
    this.setState({ loading });
  };

  //fetch activity log
  _activityLogClientClosing = async () => {
    try {
      const { activityLogPage } = this.state;

      let payload = `closingId=${this.props.match.params.id}&skip=${activityLogPage.skip}&limit=${activityLogPage.limit}`;

      const res = await activityLogClientClosing(payload);

      this.setState({
        activityLog: res?.logs,
        activityLogPage: {
          ...this.state.activityLogPage,
          totalCount: res?.totalCount,
        },
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  //pagination for activity log
  _handleActivityLogPagination = (pageNo = 1) => {
    const { activityLogPage } = this.state;

    activityLogPage["skip"] = (pageNo - 1) * activityLogPage.limit;

    this.setState({ activityLogPage }, () => {
      this._activityLogClientClosing();
    });
  };

  _getAllLenderClients = async () => {
    try {
      const res = await getAllLenderClients();

      this.setState({
        lenderClients: res?.lenderClients?.length ? res?.lenderClients : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  _updateClosingState = (closingDetails) => {
    this.setState({ closingDetails });
  };

  _getClosingDetailsById = async () => {
    this.props.showLoader("Fetching Closing Details...");
    try {
      const { formFields } = deepClone(this.state);
      this._manageLoading("dataLoading", true);

      const res = await getClosingDetailsById(this.props?.match?.params?.id);
      console.log("MINI", res);

      this._handleActivityLogPagination();

      if (res?.closing && Object.keys(res?.closing).length) {
        formFields.isScanBackNeeded.value = res?.closing?.isScanBackNeeded
          ? res?.closing?.isScanBackNeeded
          : false;

        this.setState({ closingDetails: res?.closing, formFields });
        this._ClientGetSigningCompanyCustomOrderFormId(
          res?.closing?._signingCompany?._id
        );

        HeaderEventEmitter.dispatch("closing-details", {
          closingDetails: res?.closing,
        });
        this._manageLoading("dataLoading", false);
        document.querySelector("#scroll").scrollTo(0, 0);
      }
      this.props.hideLoader();
    } catch (error) {
      console.log("error>>", error);
      errorHandler(error);
      this._manageLoading("dataLoading", false);
      this.props.history.goBack();
      this.props.hideLoader();
    }
  };

  //get settings for client
  _getClientSettings = async () => {
    try {
      const res = await getClientSettings();

      this.setState({
        signingInstruction: res.clientSetting?.signingInstruction?.length
          ? res.clientSetting.signingInstruction
          : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  _getAllSigningCompanyForClient = async () => {
    try {
      const res = await getAllSigningCompanyForClient();

      this.setState({
        signingCompanies: res?.signingCompany?.length
          ? res?.signingCompany
          : [],
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  _downloadInvoice = async () => {
    const siteOwnerId = REDUX_STORE.getState().userData?.user?._siteOwner;
    const token = REDUX_STORE.getState().userData.token;
    console.log("this.state.closingDetails", this.state.closingDetails);

    try {
      if (this.state.closingDetails.status === "Pending") {
        openUrlOnNewTab(
          `${BASE_URL}/client/download/invoice?token=${token}&closingId=${this.state.closingDetails?._id}`
        );
      } else {
        const res = await createInvoiceClient({
          closingId: [this.state.closingDetails?._id],
        });
        openUrlOnNewTab(
          `${BASE_URL}/signingcompany/download/invoice/${res?.invoice?._id}?userId=${siteOwnerId}`
        );
      }
    } catch (error) {
      console.log("error", error);

      errorHandler(error);
    }
  };

  _getTrackingInfoByTrackingNumber = async (payload) => {
    try {
      const res = await getTrackingInfoById(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  _openTrackingDetails = () => {
    const { shippingCompany, trackingInfo } = this.state?.closingDetails;
    openTrackingDetails(shippingCompany, trackingInfo);
  };

  componentDidMount = () => {
    this._getClosingDetailsById();

    this._getAllSigningCompanyForClient();
    this._getAllLenderClients();
    this._getClientSettings();
    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
    HeaderEventEmitter.subscribe("track-order", this._openTrackingDetails);
    HeaderEventEmitter.subscribe("download-invoice-client", () => {
      this._downloadInvoice();
    });
  };

  _orderDetail = () => {
    const { closingDetails, formFields } = this.state;

    formFields["fileNumber"].value = closingDetails.fileNumber
      ? closingDetails.fileNumber
      : "";
    formFields["lenderClientId"].value = closingDetails?._lenderClient
      ? closingDetails?._lenderClient
      : "";
    formFields["signingCompanyId"].value = closingDetails?._signingCompany?._id
      ? closingDetails?._signingCompany?._id
      : "";
    formFields["signingCompanyFee"].value = closingDetails.signingCompanyFee
      ? closingDetails.signingCompanyFee.toString()
      : "";

    formFields["personOfContactName"].value = closingDetails.personOfContact
      .name
      ? closingDetails.personOfContact.name
      : "";
    formFields["personOfContactEmail"].value = closingDetails.personOfContact
      .email
      ? closingDetails.personOfContact.email.filter((x) => x)
      : [];

    formFields["loanTypeOther"].value = closingDetails.loanTypeOther
      ? closingDetails.loanTypeOther
      : "";
    formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["witnessCount"].value = Number(closingDetails.witnessCount);

    this.setState({ formFields });
  };

  _appointmentDetail = () => {
    const { closingDetails, formFields } = this.state;
    let borrower = [];

    closingDetails._borrower.forEach((each) => {
      borrower.push({
        _id: {
          value: each._id,
        },
        borrowerName: {
          value: each.name.full || "",
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: each.email || "",
          isDirty: false,
          isValidate: true,
        },
        workPhone: {
          value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
          isDirty: false,
          isValidate: true,
        },
        isForeignNational: {
          value: each.isForeignNational,
          isDirty: false,
          isValidate: true,
        },
        language: {
          value: each.language,
          isDirty: false,
          isValidate: true,
        },
        languageTypeOther: {
          value: each.languageTypeOther || "",
          isDirty: false,
          isValidate: true,
        },
        timeZone: {
          value: each.timeZone || "",
          isDirty: false,
          isValidate: true,
        },
      });
    });

    formFields["borrower"] = borrower;

    if (closingDetails?.closingAddress) {
      formFields["closingSearch"].value = formatAddressInSingleText(
        closingDetails?.closingAddress
      );
      formFields["closingSearch"].placeId = "initialValue";
    }
    if (closingDetails?._borrower[0]?.address) {
      formFields["borrowerSearch"].value = formatAddressInSingleText(
        closingDetails._borrower[0].address
      );
      formFields["borrowerSearch"].placeId = "initialValue";
    }
    formFields["additionalSigner"].value = closingDetails?.additionalSigner;

    this.setState({ formFields });
  };

  _instructionsDetail = () => {
    const { closingDetails, formFields } = this.state;
    formFields["instructionId"].value = closingDetails?.instructionId
      ? closingDetails?.instructionId
      : "";
    formFields["instructions"].value = closingDetails?.instructions
      ? closingDetails?.instructions
      : "";
    this.setState({ formFields });
  };

  _editModeOn = (fieldName, value) => {
    const { editing, formFields } = this.state;

    Object.keys(editing).forEach((key) => {
      editing[key] = false;
    });

    editing[fieldName] = value;

    this.setState({
      editing,
    });

    if (fieldName === "appointment") {
      this._appointmentDetail();
    }
    if (fieldName === "orderDetail") {
      this._orderDetail();
    }
    if (fieldName === "instructions") {
      this._instructionsDetail();
    }

    if (value === false) {
      Object.keys(formFields).forEach((key) => {
        delete formFields[key].error;
      });
      this.setState({ formFields });
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, editing } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          if (editing.appointment) {
            switch (key) {
              case "borrowerName": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "email": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(formFields[key].value).toLowerCase()
                    )
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              case "homePhone":
              case "workPhone":
              case "phone": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(formFields[key].value).toLowerCase()
                    )
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Phone Number";
                  }
                } else {
                  if (
                    (formFields["homePhone"].value &&
                      formFields["homePhone"].value.length) ||
                    (formFields["workPhone"].value &&
                      formFields["workPhone"].value.length) ||
                    (formFields["phone"].value &&
                      formFields["phone"].value.length)
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                  }
                }
                break;
              }
              case "closingSearch": {
                if (
                  this.props.location.search.length === 0 &&
                  this.state.isAppointmentSearch
                ) {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    if (formFields[key].placeId) {
                      formFields[key].isDirty = false;
                      formFields[key].error = null;
                    } else {
                      formFields[key].isDirty = true;
                      formFields[key].error = "*Invalid Address";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              default:
            }
          } else if (editing.orderDetail) {
            switch (key) {
              case "fileNumber": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "personOfContactEmail": {
                if (formFields[key].value && formFields[key].value.length) {
                  formFields.personOfContactEmail.value.forEach(
                    (each, index) => {
                      if (RegexConfig.email.test(String(each).toLowerCase())) {
                        formFields[key].isDirty = false;
                        formFields[key].error = null;
                      } else {
                        formFields[key].isDirty = true;
                        formFields[key].error = "*Invalid Email";
                        isFormValid = false;
                      }
                    }
                  );
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              case "loanType": {
                if (formFields[key].value && formFields[key].value.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "loanTypeOther": {
                if (formFields.loanType.value === "Other") {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }

              default:
            }
          } else {
            formFields[key].isDirty = false;
            formFields[key].error = null;
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction } = this.state;

    if (fieldName === "signingCompanyFee" || fieldName === "agentFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "instructionId" && value) {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructions.value = findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  // update CLosing Detail by Id
  _updateClosing = async (type) => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this._manageLoading("cardUpdateLoading", true);

        const { formFields, closingDetails, editing } = this.state;

        let payload = {};

        if (type === "appointment") {
          payload = {
            borrower: [],
            additionalSigner:
              formFields.additionalSigner?.value?.length > 0
                ? formFields.additionalSigner.value
                : undefined,
            closingAddress: {},
          };
          let borrower = await formFields.borrower.map(
            ({
              _id,
              borrowerName,
              email,
              workPhone,
              isForeignNational,
              language,
              languageTypeOther,
              timeZone,
            }) => {
              const splitBorrowerName = borrowerName.value.trim().split(" ");

              return {
                name: {
                  first: splitBorrowerName.slice(0, 1).join(" "),
                  last: splitBorrowerName[1]
                    ? splitBorrowerName.slice(1).join(" ")
                    : "",
                },
                _id: _id.value,
                email: email.value ? email.value : undefined,
                phone: { work: workPhone.value ? workPhone.value : undefined },
                isForeignNational: isForeignNational.value,

                language: language.value ? language.value : undefined,
                languageTypeOther: languageTypeOther.value,

                timeZone: timeZone.value ? timeZone.value : undefined,
                address: {},
              };
            }
          );
          payload["borrower"] = borrower;

          // if (
          //   formFields.closingSearch.placeId &&
          //   formFields.closingSearch.placeId !== "initialValue"
          // ) {
          //   const addressObj = await googlePlaceDetails(
          //     formFields.closingSearch.placeId
          //   );

          //   let abbr = usaStates.find(
          //     (item) => item.name === addressObj.state
          //   ).abbreviation;

          //   payload.closingAddress["line1"] = addressObj.address;
          //   payload.closingAddress["city"] = addressObj.city;
          //   payload.closingAddress["county"] = addressObj.county;
          //   payload.closingAddress["state"] =
          //     addressObj.state.length > 2 ? abbr : addressObj.state;
          //   payload.closingAddress["zip"] = addressObj.postal;
          //   payload.closingAddress["lat"] = addressObj.lat;
          //   payload.closingAddress["lng"] = addressObj.lng;
          // } else {
          //   delete payload.closingAddress;
          // }

          // if (
          //   formFields.borrowerSearch.placeId &&
          //   formFields.borrowerSearch.placeId !== "initialValue"
          // ) {
          //   const addressObj = await googlePlaceDetails(
          //     formFields.borrowerSearch.placeId
          //   );

          //   let abbr = usaStates.find(
          //     (item) => item.name === addressObj.state
          //   ).abbreviation;

          //   borrower?.map(
          //     (item) => (
          //       // eslint-disable-next-line no-sequences
          //       (item.address["line1"] = addressObj.address),
          //       (item.address["city"] = addressObj.city),
          //       (item.address["county"] = addressObj.county),
          //       (item.address["state"] =
          //         addressObj.state.length > 2 ? abbr : addressObj.state),
          //       (item.address["zip"] = addressObj.postal),
          //       (item.address["lat"] = addressObj.lat),
          //       (item.address["lng"] = addressObj.lng)
          //     )
          //   );
          // }
        }

        if (type === "orderDetail") {
          payload = {
            fileNumber: formFields.fileNumber.value || undefined,
            signingCompanyFee: formFields.signingCompanyFee.value || undefined,
            signingCompanyId: formFields.signingCompanyId?.value?.trim().length
              ? formFields.signingCompanyId.value !==
                closingDetails?._signingCompany?.id
                ? formFields.signingCompanyId.value
                : undefined
              : undefined,
            lenderClientId: formFields.lenderClientId.value || undefined,
            personOfContact: {
              name: formFields.personOfContactName.value || undefined,
              email:
                formFields.personOfContactEmail.value.length > 0
                  ? formFields.personOfContactEmail.value.toString()
                  : [],
            },
            loanTypeOther: formFields.loanTypeOther.value || undefined,
          };
          if (formFields.loanType.value) {
            payload["loanType"] = await formFields.loanType.value.map(
              (item) => item.value
            );
          }
          if (
            formFields.witnessCount.value &&
            formFields.witnessCount.value.trim().length
          ) {
            payload["witnessCount"] = Number(
              formFields.witnessCount.value.trim()
            );
          }
        }

        if (type === "instructions") {
          payload = {
            instructionId: formFields.instructionId.value || undefined,
            instructions: formFields.instructions.value || undefined,
          };
        }

        try {
          await this._updateClosingDetailsById(closingDetails.id, payload);

          showToast("Order has been updated", "success");

          Object.keys(editing).forEach((key) => {
            editing[key] = false;
          });
          this.setState({ editing });
          this._getClosingDetailsById();
          this._manageLoading("cardUpdateLoading", false);
        } catch (error) {
          errorHandler(error);
          this._manageLoading("cardUpdateLoading", false);
        }
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("cardUpdateLoading", false);
    }
  };

  // signing  availabilty modal open/close
  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    if (
      isOpen &&
      (data?._agent ||
        (data?.status !== "Pending" && data?.status !== "Cancelled"))
    ) {
      errorHandler({ reason: "You cannot change the date & time." });
      return;
    }

    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onChangeOrderStatus = async (data, status) => {
    try {
      if (status !== "Pending" && status !== "Cancelled") {
        showToast("Invalid status", "error");
        return;
      }

      this._manageLoading("statusChangeLoading", true);

      const updateAgentRes = await this._updateClosingDetailsById(data.id, {
        status: status,
      });

      showToast("Status has been updated", "success");

      const { closingDetails } = this.state;

      closingDetails["status"] = updateAgentRes?.closing?.status || "";

      this.setState({ closingDetails });

      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  // update closing status by id
  _updateClosingDetailsByIdStatus = async (data, status) => {
    try {
      this._manageLoading("statusChangeLoading", true);

      const updateAgentRes = await this._updateClosingDetailsById(
        data.id,
        status
      );
      showToast("Status has been updated", "success");

      const { formFields } = deepClone(this.state);

      formFields["isScanBackNeeded"].value =
        updateAgentRes.closing.isScanBackNeeded;

      this.setState({ formFields });

      this._handleActivityLogPagination();
      this._getClosingDetailsById();
      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  // update CLosing Detail by Id
  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _toggleEditOrderDetailsModal = (isOpen = false, data = null) => {
    this.setState({
      editOrderDetailsModal: {
        isOpen,
        data,
      },
    });
  };

  //get companay custom order from
  _ClientGetSigningCompanyCustomOrderFormId = async (id) => {
    if (id.trim().length > 0) {
      try {
        let response = await ClientGetSigningCompanyCustomOrderFormId(id);

        if (response?.productTypes) {
          this._setProductType(response?.productTypes);
        }
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  _setProductType = (products) => {
    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    this.setState({ loanType: groupedProducts });
  };

  _updateFocusTextEditor = (type) => {
    if (type === "focus") {
      this.setState({ isFocusTextEditor: true });
    } else {
      this.setState({ isFocusTextEditor: false });
    }
  };

  render() {
    const {
      formFields,
      closingDetails,
      loading,
      editing,
      lenderClients,
      signingCompanies,
      activityLog,
      activityLogPage,
      signingInstruction,
      signerAvailabilityModal,
      editOrderDetailsModal,
    } = this.state;

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>
                Order Details
                {closingDetails?._createdBy?.name?.full ||
                closingDetails?._createdByClient?.name?.full ? (
                  <span>
                    Created by{" "}
                    {closingDetails?._createdBy?.name?.full ||
                      closingDetails?._createdByClient?.name?.full}
                  </span>
                ) : null}
              </h2>
            </div>

            <div className="rightSide">
              <Button
                className="floatingButton"
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    `/client/create-order?${closingDetails?.id}`
                  )
                }
              >
                Clone
              </Button>
              <Button
                color="primary"
                onClick={this._openTrackingDetails}
                disabled={!closingDetails?.shippingCompany?.trim()}
              >
                Track Shipment
              </Button>
              <Button
                color="primary"
                onClick={() => this._downloadInvoice()}
                disabled={
                  closingDetails?.status === "Cancelled" ||
                  closingDetails?.status === "CCA" ||
                  closingDetails?.status === "Arrived"
                }
              >
                Download Invoice
              </Button>
            </div>
          </div>

          <>
            <div className="CardWrapper">
              <div className="CardTitle">
                <h2 className="sectionTtile">
                  {" "}
                  Appointment{" "}
                  {closingDetails?.status !== "Closed" &&
                  closingDetails?.status !== "DNC" &&
                  // only open if closing is create by Client
                  closingDetails &&
                  closingDetails?._createdByClient !== undefined ? (
                    <Button
                      color="link"
                      onClick={() =>
                        this._toggleEditOrderDetailsModal(true, closingDetails)
                      }
                      disabled={
                        !checkPermission("dashboard", "canEditOrderEntry")
                      }
                    >
                      <img
                        src={require("../../../assets/img/pencil.svg").default}
                        alt="edit "
                      />
                    </Button>
                  ) : null}
                </h2>
              </div>
            </div>

            <SignerAppointment
              closingDetails={closingDetails}
              updateClosingDetailsByIdStatus={(closingDetails, status) =>
                this._updateClosingDetailsByIdStatus(closingDetails, status)
              }
              userType="client"
            />

            <Row className="mt-4">
              <Col xl="6">
                <div className="CardWrapper">
                  <div className="CardTitle">
                    <h2 className="sectionTtile">Secure Documents </h2>
                  </div>
                  <Suspense fallback={<></>}>
                    <SecureDocumentCardComponent
                      closingDetails={closingDetails}
                      updateClosingState={(closingDetails) =>
                        this._updateClosingState(closingDetails)
                      }
                      loadingData={this.props.loadingData}
                    />
                  </Suspense>
                </div>
              </Col>

              <Col xl="6">
                <Card className={`noataryAgent`}>
                  <CardHeader>
                    <CardTitle>
                      {closingDetails?._agent?.workType
                        ? closingDetails?._agent.workType
                        : "Agent"}{" "}
                      Details
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="p-0">
                    {closingDetails?._agent ? (
                      <>
                        <Label className="uploadProfile">
                          {/* <Input
                            type="file"
                            style={{ display: "none" }}
                            accept="image/x-png,image/gif,image/jpeg"
                            value=""
                            onChange={(event) =>
                              this._updateFileImage("logo", event)
                            }
                          /> */}
                          <img
                            src={
                              closingDetails?._agent?.profilePicture ||
                              require("../../../assets/img/placeholder-img.png")
                            }
                            alt="logo"
                            style={{ objectFit: "cover" }}
                          />
                          {/* <i className="fa fa-pencil" /> */}
                        </Label>

                        <ul className="noataryAgentList">
                          <li>
                            Name
                            <div className="value">
                              {capitalize(closingDetails?._agent?.name?.full)}
                            </div>
                          </li>
                          <li>
                            Location
                            <div className="value">
                              {closingDetails?._agent?.address
                                ? formatAddressInSingleText(
                                    closingDetails._agent.address
                                  ) || "N/A"
                                : "N/A"}
                            </div>
                          </li>
                          {closingDetails?.agentVisibilityForClient ===
                          "yes" ? (
                            <>
                              <li>
                                Email
                                <div className="value">
                                  {closingDetails?._agent?.email || "N/A"}
                                </div>
                              </li>
                              <li>
                                Phone
                                <div className="value">
                                  {closingDetails?._agent?.phone || "N/A"}
                                </div>
                              </li>
                            </>
                          ) : (
                            ""
                          )}
                          <li>
                            Rating
                            <div className="rating">
                              <StarRatings
                                rating={closingDetails?._agent?.totalRating}
                                starRatedColor="#FED82F"
                                changeRating={
                                  closingDetails?._agent?.totalRating
                                }
                                starHoverColor="#FED82F"
                                numberOfStars={5}
                                name="rating"
                              />
                            </div>
                          </li>
                        </ul>
                      </>
                    ) : (
                      <div className="p-3">
                        Agent has not been assigned yet.
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* chat log */}
            <Suspense fallback={<></>}>
              <ChatLogCardComponent closingDetails={closingDetails} />
            </Suspense>

            {/* Order Instructions */}
            <OrderDetailsInstructions
              editing={editing}
              loading={loading}
              formFields={formFields}
              closingDetails={closingDetails}
              signingInstruction={signingInstruction}
              isFocusTextEditor={this.state.isFocusTextEditor}
              editModeOn={this._editModeOn}
              onChangeFormField={this._onChangeFormField}
              updateClosingDetailsByIdStatus={
                this._updateClosingDetailsByIdStatus
              }
              updateClosing={this._updateClosing}
              updateFocusTextEditor={(type) =>
                this._updateFocusTextEditor(type)
              }
            />

            {/* activity */}
            <Suspense fallback={<></>}>
              <ClientActivityLog
                activityLog={activityLog}
                closingDetails={closingDetails}
                activityLogPage={activityLogPage}
                handleActivityLogPagination={(pageNo) =>
                  this._handleActivityLogPagination(pageNo)
                }
              />
            </Suspense>
          </>
        </div>

        <Suspense fallback={<></>}>
          <SignerAvailabilityModal
            isOpen={signerAvailabilityModal.isOpen}
            data={signerAvailabilityModal.data}
            resetDetails={this._getClosingDetailsById}
            toggle={() => this._onToggleSignerAvailabilityModal()}
            AppointmentSchedulerConfig={{
              regular: { disable: false, show: true },
              dateRange: { disable: true, show: true },
              tbd: { disable: false, show: true },
            }}
          />
        </Suspense>

        <Suspense fallback={<></>}>
          <EditOrderDetailsModal
            isOpen={editOrderDetailsModal.isOpen}
            data={editOrderDetailsModal.data}
            toggle={() => this._toggleEditOrderDetailsModal()}
            updateClosingDetailsById={this._updateClosingDetailsById}
            resetDetails={this._getClosingDetailsById}
            clients={lenderClients}
            signingCompanies={signingCompanies}
            loanType={this.state.loanType}
            AppointmentSchedulerConfig={{
              regular: { disable: false, show: true },
              dateRange: { disable: true, show: true },
              tbd: { disable: false, show: true },
            }}
          />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
    settings: state.settings,
    loadingData: state.loaderData ? state.loaderData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
