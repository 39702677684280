import React, { useState } from "react";
import {
  Card,
  CardBody,
  CustomInput,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import TextEditor from "./TextEdittor";
import { capitalize } from "../../../helper-methods";
import ReadMore from "./readMore";
// import ReadMore from "./readMore";

export default function Instructions({
  instructionData,
  paymentInstructionsData = null,
  hideScanback = false,
  onChangeFormField,
  signingInstruction = [],
  hideInstructionId = false,
  instructionIdData,
  isScanBackNeededData,
  scanbackForDetailPage,
  closingDetails,
  updateClosingDetailsById,
  disableScanback,
  questions,
  onChangeQuestionFormField,
  userType = "",
}) {
  const [isFocusTextEditor, setIsFocusTextEditor] = useState(false);

  const updateScanback = (event) => {
    if (scanbackForDetailPage) {
      updateClosingDetailsById(closingDetails.id, {
        isScanBackNeeded: event.target.checked,
      });
    }
  };

  return (
    <>
      {!hideInstructionId ? (
        <FormGroup
          style={{ maxWidth: 300 }}
          className="floatingLabel custom-select-wrapper"
        >
          <Input
            type="select"
            placeholder=" "
            value={instructionIdData}
            name="instructionId"
            onChange={(event) =>
              onChangeFormField("instructionId", event.target.value)
            }
          >
            <option value="">Select</option>
            {signingInstruction?.length > 0
              ? signingInstruction.map((each) => (
                  <option key={each._id} value={each._id}>
                    {capitalize(each.name)}
                  </option>
                ))
              : null}
          </Input>
          <Label>Instruction Type</Label>
        </FormGroup>
      ) : null}

      <FormGroup
        className={`mb-0 floatingLabel reactQuill ${
          (instructionData && instructionData !== "<p><br></p>") ||
          isFocusTextEditor
            ? "valueAdded"
            : ""
        }`}
      >
        <Label>Signing Instructions</Label>
        <TextEditor
          content={instructionData}
          onChange={(event) => onChangeFormField("instructions", event)}
          onFocus={() => setIsFocusTextEditor(true)}
          onBlur={() => setIsFocusTextEditor(false)}
        />
        {/* <ReadMore text={instructionData} /> */}
      </FormGroup>

      {/* {userType === "signingcompany" && (
            <FormGroup
              className={`mt-3 mb-3 floatingLabel reactQuill ${
                (paymentInstructionsData &&
                  paymentInstructionsData !== "<p><br></p>") ||
                isFocusTextEditorPay
                  ? "valueAdded"
                  : ""
              }`}
            >
              <Label>Payment Instructions</Label>
              <TextEditor
                content={paymentInstructionsData}
                onChange={(event) =>
                  onChangeFormField("paymentInstructions", event)
                }
                onFocus={() => setIsFocusTextEditorPay(true)}
                onBlur={() => setIsFocusTextEditorPay(false)}
              />
            </FormGroup>
          )} */}

      {userType === "agent" && closingDetails.paymentInstructions && (
        <div className="mt-3">
          <Label>Payment Instructions</Label>
          <ReadMore text={closingDetails.paymentInstructions} />
        </div>
      )}

      {!hideScanback && (
        <>
          <h2
            style={{
              fontSize: 14,
              fontWeight: 500,
              marginBottom: 20,
              marginTop: 20,
            }}
          >
            Additional Instruction
          </h2>
          <CustomInput
            type="checkbox"
            id="isScanBackNeeded"
            label="Scan Backs Needed"
            className="mb-2"
            checked={isScanBackNeededData}
            onChange={(event) =>
              scanbackForDetailPage
                ? updateScanback(event)
                : onChangeFormField("isScanBackNeeded", event.target.checked)
            }
            disabled={disableScanback}
          />
        </>
      )}

      {questions?.length > 0
        ? questions?.map((question, index) => (
            <CustomInput
              type="checkbox"
              id={`demoQuestion
                      +${index}`}
              label={question.query}
              className="mb-2"
              checked={question.reply}
              onChange={(event) => onChangeQuestionFormField("question", index)}
            />
          ))
        : ""}
    </>
  );
}
