import React, { Suspense, lazy } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  CustomInput,
  Input,
  Row,
  TabContent,
  TabPane,
  Table,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label,
} from "reactstrap";
import {
  capitalize,
  formatAddressInSingleText,
  formatDateHideYearIfSame,
  getDropdownColor,
} from "../../../helper-methods";
import {
  DEFAULT_PROFILE_PICTURE,
  clientTeamMembersConfig,
} from "../../../config";
import SvgIcons from "./SvgIcons";
import SkeletonLoading from "./Skeletons/SkeletonLoading";
import CustomTable from "./CustomTable";
import CustomPagination from "./CustomPagination";
import CustomAgentDetailsforClient from "./CustomAgentDetailsforClient";
import ClientPaymentVisibility from "./ClientPaymentVisibility";

const CompanyDocuments = lazy(() => import("./CompanyDocuments"));
const CompanyNotes = lazy(() => import("./CompanyNotes"));
const CustomCard = lazy(() => import("./CustomCard"));
const CustomOrderQuestion = lazy(() => import("./CustomOrderQuestion"));
const CustomProductVisibility = lazy(() => import("./CustomProductVisibility"));

const ClientDetailsTabComponent = ({
  activeTab,
  client,
  loading,
  assistantTeams,
  isForMobile,
  teamMembers,
  filters,
  clientMembersCardHeaderKeys,
  _onToggleTeamMemberModal,
  isOpenCollapse,
  _handleCollapse,
  _deleteCompanyAssistantTeamAlert,
  _onToggleAddUserModal,
  _calculatePermissionCount,
  _userStatusUpdate,
  _cardHeaderFormat,
  _cardDataFormat,
  _getClientConnectByCompany,
  reduxLoading,
  _resendClientInvitation,
  _filterOnChange,
  tableConfig,
  _handlePageChange,
}) => {
  return (
    <>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="clientDetailsTab">
          <Card className="aboutCompany signingClientDetails">
            <CardBody>
              <div className="companyImage">
                <img
                  src={client?.logo ? client.logo : DEFAULT_PROFILE_PICTURE}
                  alt="Profile Img"
                />
              </div>
              <div className="clientContent">
                <h5>
                  {client?.companyName ? capitalize(client.companyName) : "N/A"}
                </h5>
                <ul>
                  <li>
                    <img
                      src={
                        require("../../../assets/img/mapMarker_blue.svg")
                          .default
                      }
                      alt="location"
                      height={14}
                    />
                    {formatAddressInSingleText(client?.address) || "N/A"}
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>

          <Card className="teamMembers">
            <CardHeader>
              <CardTitle>Teams</CardTitle>
              <Button
                color="link"
                className="readMore"
                onClick={() => _onToggleTeamMemberModal(true)}
              >
                Add Teams
              </Button>
            </CardHeader>
            <CardBody>
              {reduxLoading ? (
                <SkeletonLoading
                  type="card"
                  count={5}
                  height={50}
                  width={isForMobile ? 330 : 1250}
                />
              ) : assistantTeams?.length ? (
                assistantTeams?.map((each, index) => (
                  <div
                    className={`teamsAccordian ${
                      isOpenCollapse === each?._id ? "open" : ""
                    }`}
                  >
                    <div className="teamsHead">
                      <span onClick={() => _handleCollapse(each?._id)}>
                        {each?.teamName}{" "}
                        {each?.uniqueId ? `(${each?.uniqueId})` : ""}
                      </span>
                      <div>
                        <Button
                          className="pencilIcon"
                          color="link"
                          onClick={() => _onToggleTeamMemberModal(true, each)}
                          disabled={!client?.isEditable}
                        >
                          <SvgIcons type={"pencil"} />
                        </Button>
                        {each.teamName !== "Primary Team" && (
                          <Button
                            className="remove"
                            color="link"
                            onClick={() =>
                              _deleteCompanyAssistantTeamAlert(each?._id, index)
                            }
                            disabled={!client?.isEditable}
                          >
                            <SvgIcons type={"remove"} />
                          </Button>
                        )}
                        <i
                          onClick={() => _handleCollapse(each?._id)}
                          className={`fa fa-${
                            isOpenCollapse === each?._id
                              ? "chevron-up"
                              : "chevron-down"
                          }`}
                        />
                      </div>
                    </div>
                    <Collapse
                      className="teamsBody"
                      isOpen={isOpenCollapse === each?._id ? true : false}
                    >
                      {each?.teamMembers?.filter((subEach) => subEach?._user)
                        ?.length ? (
                        <>
                          <h2>Members</h2>
                          {each?.teamMembers
                            ?.filter((subEach) => subEach?._user)
                            ?.map((teamMember) => (
                              <div className="">
                                <ul>
                                  <li>
                                    <h3>
                                      {teamMember?._user?.name?.full
                                        ? teamMember?._user?.name?.full
                                        : teamMember?._user?.name?.first}
                                      {teamMember?._user?.isActive === false ? (
                                        <span className="text-danger">
                                          (Inactive)
                                        </span>
                                      ) : null}
                                    </h3>
                                    {clientTeamMembersConfig?.map(
                                      (permissionKey, index) => (
                                        <CustomInput
                                          key={permissionKey?.value}
                                          type="checkbox"
                                          id={`memberPermissionCheckbox_${permissionKey?.value}}`}
                                          label={permissionKey?.label}
                                          inline
                                          checked={teamMember?.roles?.includes(
                                            index + 1
                                          )}
                                          disabled={true}
                                        />
                                      )
                                    )}
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </>
                      ) : null}

                      {each?.teamMembers?.filter((subEach) => !subEach?._user)
                        ?.length ? (
                        <>
                          <h2>CC Members</h2>
                          {each?.teamMembers
                            ?.filter((subEach) => !subEach?._user)
                            ?.map((teamMember) => (
                              <div className="">
                                <ul>
                                  <li>
                                    <h3>{teamMember?.name?.full}</h3>
                                    {clientTeamMembersConfig?.map(
                                      (permissionKey, index) => (
                                        <CustomInput
                                          key={permissionKey?.value}
                                          type="checkbox"
                                          id={`memberPermissionCheckbox_${permissionKey?.value}}`}
                                          label={permissionKey?.label}
                                          inline
                                          checked={teamMember?.roles?.includes(
                                            index + 1
                                          )}
                                          disabled={true}
                                        />
                                      )
                                    )}
                                  </li>
                                </ul>
                              </div>
                            ))}
                        </>
                      ) : null}
                    </Collapse>
                  </div>
                ))
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              )}
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>Stats</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="6" md="3">
                  {reduxLoading ? (
                    <SkeletonLoading
                      type="card"
                      count={1}
                      height={isForMobile ? 130 : 80}
                      width={isForMobile ? 150 : 300}
                    />
                  ) : (
                    <div className="CompanyStats">
                      <div>
                        <h6>Jobs Completed</h6>
                        <p>
                          {client?.jobsCompleted >= 0
                            ? client?.jobsCompleted
                            : "N/A"}
                        </p>
                      </div>
                      <div className="icon">
                        <img
                          src={require("../../../assets/img/JobCompleted.png")}
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs="6" md="3">
                  {reduxLoading ? (
                    <SkeletonLoading
                      type="card"
                      count={1}
                      height={isForMobile ? 130 : 80}
                      width={isForMobile ? 150 : 300}
                    />
                  ) : (
                    <div className="CompanyStats">
                      <div>
                        <h6>Jobs Scheduled</h6>
                        <p>
                          {client?.jobsScheduled >= 0
                            ? client?.jobsScheduled
                            : "N/A"}
                        </p>
                      </div>
                      <div className="icon">
                        <img
                          src={require("../../../assets/img/JobsScheduled.png")}
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs="6" md="3">
                  {reduxLoading ? (
                    <SkeletonLoading
                      type="card"
                      count={1}
                      height={isForMobile ? 130 : 80}
                      width={isForMobile ? 150 : 300}
                    />
                  ) : (
                    <div className="CompanyStats">
                      <div>
                        <h6>Invoices Paid</h6>
                        <p>
                          {client?.invoicesPaid >= 0
                            ? client?.invoicesPaid
                            : "N/A"}
                        </p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalIncome.svg")
                              .default
                          }
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  )}
                </Col>
                <Col xs="6" md="3">
                  {reduxLoading ? (
                    <SkeletonLoading
                      type="card"
                      count={1}
                      height={isForMobile ? 130 : 80}
                      width={isForMobile ? 150 : 300}
                    />
                  ) : (
                    <div className="CompanyStats">
                      <div>
                        <h6>Invoices Pending</h6>
                        <p>
                          {" "}
                          {client?.invoicesPending >= 0
                            ? client?.invoicesPending
                            : "N/A"}
                        </p>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/invoicepending.svg")
                              .default
                          }
                          alt="JobCompleted"
                        />
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Suspense fallback={<></>}>
            <CompanyDocuments data={client} />
            <CompanyNotes data={client} />
          </Suspense>
        </TabPane>

        <TabPane tabId="ManageMember">
          <div className="d-flex justify-content-between align-items-end align-items-md-baseline flex-column flex-md-row">
            <div className="mobileSearchFiled">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={
                        require("../../../assets/img/searchIcon.svg").default
                      }
                      alt="searchIcon"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="search"
                  value={filters?.search}
                  onChange={(e) =>
                    _filterOnChange("search", e.target.value, true)
                  }
                  placeholder="Search By Name"
                />
              </InputGroup>
            </div>
            <div className="filterContainer responsiveFilter">
              <div className="filterIcon">
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="filter icon"
                />
                Filter by
              </div>
              <div className="filterWrapper">
                <FormGroup className="searchTable">
                  <Label>Search</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/searchIcon.svg")
                              .default
                          }
                          alt="searchIcon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Search By Name"
                      value={filters?.search}
                      onChange={(e) =>
                        _filterOnChange("search", e.target.value, true)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </div>
            <div className="text-right mb-3">
              {/* {isEditable ? ( */}
              <Button
                color="primary"
                size="md"
                onClick={() => _onToggleAddUserModal(true)}
              >
                ADD TEAM MEMBER
              </Button>
              {/* ) : null} */}
            </div>
          </div>

          {!isForMobile ? (
            <Suspense fallback={<></>}>
              <div className="CustomTableWrapper hideMobile">
                <Table striped responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>Permission</th>
                      <th>Added by</th>
                      <th>Last Login</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading.showTableLoading ? (
                      <SkeletonLoading
                        type="table"
                        height={50}
                        rows={10}
                        col={8}
                      />
                    ) : teamMembers?.data?.length ? (
                      teamMembers.data.map((teamMember, index) => (
                        <tr key={index}>
                          <td>
                            <div style={{ width: 120 }}>
                              {teamMember?.name?.full}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: 120 }}>
                              <a href={`mailto:${teamMember?.email}`}>
                                {teamMember?.email}
                              </a>
                            </div>
                          </td>
                          <td>
                            <div style={{ width: 110 }}>
                              {teamMember?.phone}
                            </div>
                          </td>
                          <td>
                            <div style={{ width: 40 }}>
                              {teamMember?.permissionSettings
                                ? _calculatePermissionCount(
                                    teamMember.permissionSettings
                                  )
                                : 0}
                              /6
                            </div>
                          </td>
                          <td>
                            <div style={{ width: 150 }}>
                              {teamMember._createdBy ||
                              teamMember._createdByClient
                                ? teamMember._createdBy?.companyName ||
                                  teamMember._createdByClient?.name?.full
                                : "N/A"}
                            </div>
                          </td>
                          <td>
                            {teamMember?.lastLogin
                              ? formatDateHideYearIfSame(teamMember.lastLogin)
                              : "Not Logged In Yet!"}
                          </td>
                          <td>
                            <div
                              className="customSelectWrap"
                              style={{ width: 100 }}
                            >
                              <Input
                                type="select"
                                name="status"
                                className={`status ${getDropdownColor(
                                  teamMember.isActive ? "active" : "inactive"
                                )}`}
                                disabled={loading.requestLoading}
                                value={
                                  teamMember.isActive ? "active" : "inactive"
                                }
                                onChange={() => _userStatusUpdate(teamMember)}
                              >
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                              </Input>
                              <div className="downArrow">
                                <i className="fa fa-chevron-down"></i>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-wrap">
                              <Button
                                onClick={() =>
                                  _onToggleAddUserModal(true, teamMember)
                                }
                                color="link"
                                id="pencil_Edit"
                              >
                                <SvgIcons type="pencil" />
                              </Button>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="pencil_Edit"
                              >
                                Edit
                              </UncontrolledTooltip>
                              <Button
                                color="link"
                                className="remove resendInvitation"
                                onClick={() =>
                                  _resendClientInvitation(teamMember)
                                }
                                id="email_enevlope_open"
                              >
                                <SvgIcons type="resendEmail" />
                              </Button>
                              <UncontrolledTooltip
                                placement="bottom"
                                target="email_enevlope_open"
                              >
                                Resend invitation
                              </UncontrolledTooltip>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="8"
                          className="nodata"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            src={
                              require("../../../assets/img/noData.svg").default
                            }
                            alt="no data"
                          />
                          <p>There is no data to display</p>
                          {/* No Data Found */}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                {/* Render CustomPagination outside tbody */}
                {!loading.showTableLoading && teamMembers?.data?.length > 0 && (
                  <div style={{ margin: "20px" }}>
                    <CustomPagination
                      data={teamMembers.data}
                      dataCount={teamMembers.totalCount || 0}
                      pageNumber={tableConfig.pageNumber || 1}
                      pageSize={tableConfig.limit || 10}
                      onPageChange={(pageNumber) => {
                        console.log(pageNumber);
                        _handlePageChange(pageNumber);
                      }}
                      isHideForLessData={true}
                      isPageStartFromOne={true}
                    />
                  </div>
                )}
              </div>
            </Suspense>
          ) : (
            <Suspense fallback={<></>}>
              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  tableData={teamMembers?.data}
                  isPagination={false}
                  headerKeys={clientMembersCardHeaderKeys}
                  rowSelection={false}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={_cardHeaderFormat}
                  cardDataFormat={_cardDataFormat}
                />
                <CustomPagination
                  data={teamMembers.data}
                  dataCount={teamMembers.totalCount || 0}
                  pageNumber={tableConfig.pageNumber || 1}
                  pageSize={tableConfig.limit || 10}
                  onPageChange={(pageNumber) => {
                    console.log(pageNumber);
                    _handlePageChange(pageNumber);
                  }}
                  isHideForLessData={true}
                  isPageStartFromOne={true}
                />
              </div>
            </Suspense>
          )}
        </TabPane>
        <TabPane tabId="customOrder">
          <Suspense fallback={<></>}>
            <CustomOrderQuestion
              client={client}
              refresh={() => _getClientConnectByCompany()}
            />
          </Suspense>
        </TabPane>
        <TabPane tabId="CustomProduct">
          <Suspense fallback={<></>}>
            <CustomProductVisibility
              client={client}
              refresh={() => _getClientConnectByCompany()}
            />
          </Suspense>
        </TabPane>

        <TabPane tabId="AgentDetails">
          <Suspense fallback={<></>}>
            <CustomAgentDetailsforClient
              client={client}
              refresh={() => _getClientConnectByCompany()}
            ></CustomAgentDetailsforClient>
          </Suspense>
        </TabPane>
        <TabPane tabId="clientVisibility">
          <Suspense fallback={<></>}>
            <ClientPaymentVisibility
              client={client}
              refresh={() => _getClientConnectByCompany()}
            />
          </Suspense>
        </TabPane>
      </TabContent>
    </>
  );
};

export default ClientDetailsTabComponent;
