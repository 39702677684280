import React, { Component } from "react";
import { Row, Col, Input, Button, FormGroup, Label } from "reactstrap";
import moment from "moment";
import momenttz from "moment-timezone";
import Swal from "sweetalert2";
import {
  capitalize,
  deepClone,
  showToast,
  getLoggedInUserId,
  structureQueryParams,
  openUrlOnNewTab,
  formatOnlyDateMoment,
  refreshFunc,
  deleteLocalStorageData,
  errorHandler,
  isProPlusUser,
} from "../../../helper-methods";
import {
  getAgentClosingMasterSchedule,
  userGoogleCalendarSync,
} from "../../../http/http-calls";
import GoogleCalendarSyncModal from "../components/google-calendar-sync-modal";
import { makeGetRequest } from "../../../http/http-service";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import { agentClosingStatus, BASE_URL } from "../../../config";
import ReactBigCalendar from "../components/calendar/ReactBigCalendar";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { datetime, RRule } from "rrule";

class ClosingMasterScheduleAgent extends Component {
  state = {
    closings: [],
    closingsTotalCount: 0,
    googleEvents: [],
    googleEventsTotalCount: 0,
    events: [],
    alert: null,
    filters: {
      client: "",
      status: "",
    },
    dropdownValue: {
      clients: [], // Signing Company Names <string array>
    },
    calendarView: "month",
    // by default dateRange set according to calendarView - day
    dateRange: {
      // yesterday - end of day Date
      // startDate: moment().subtract(1, "week").endOf("week"),
      // today - end of day Date
      // endDate: moment().endOf("week"),
      startDate: moment().startOf("month").toDate(),
      endDate: moment().endOf("month").toDate(),
    },
    isOpenGoogleCalendarSyncModal: false,
    loading: {
      showDataLoading: false,
    },
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    navigationDate: "",
    isClassAdded: false,
    isGoogleCalendarSignInDone: false,
  };
  // manage loading
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  // date range get start date and end date
  _dateRange = () => {
    const { dateRange, calendarView, navigationDate } = deepClone(this.state);

    switch (calendarView) {
      case "month": {
        let middate = new Date(
          (dateRange.startDate.getTime() + dateRange.endDate.getTime()) / 2
        );
        let endDate = moment(middate).endOf("month")._d;
        let startDate = moment(middate).startOf("month")._d;

        return [startDate, endDate];
      }
      case "day": {
        let givenDate = moment(dateRange?.endDate?._d);

        if (dateRange?.endDate?._d) {
          if (moment().isSame(givenDate, "day")) {
            const currentDate = moment().endOf("day")._d;
            const startOfDate = moment().startOf("day")._d;

            return [startOfDate, currentDate];
          } else {
            let startDate = moment(dateRange.startDate)
              .add(1, "days")
              .startOf("day")._d;
            let endDate = moment(dateRange.endDate).endOf("day")._d;

            return [startDate, endDate];
          }
        } else {
          let startDate = moment(navigationDate)
            .startOf("month")
            .startOf("week")._i;
          let endDate = moment(navigationDate).endOf("month").endOf("week")._i;

          this.setState({
            dateRange: {
              startDate,
              endDate,
            },
          });
          return [startDate, endDate];
        }
      }
      case "week": {
        const startDate = moment(dateRange.startDate).add(1, "days");
        return [startDate, dateRange.endDate];
      }
      default: {
        return dateRange;
      }
    }
  };
  //  get agent closing master schedule
  _getAgentClosingMasterSchedule = () => {
    this._manageLoading("showDataLoading", true);

    const { calendarView, dropdownValue } = deepClone(this.state);

    let date = this._dateRange(calendarView);

    let payload = {
      startDate: formatOnlyDateMoment(date[0]),
      endDate: formatOnlyDateMoment(date[1]),
    };

    getAgentClosingMasterSchedule(payload)
      .then((res) => {
        if (res?.closings && res.closings.length) {
          dropdownValue["clients"] = res.closings.map((closing) => ({
            value:
              closing?._signingCompany?._id ||
              closing?._client?._id ||
              closing?._assistant?._id,
            label:
              closing?._signingCompany?.companyName ||
              closing?._client?.name.full ||
              closing?._assistant?.name.full,
          }));
          // removing duplicates if exists
          dropdownValue["clients"] = dropdownValue["clients"].filter(
            (item, index, self) =>
              index === self.findIndex((client) => client.value === item.value)
          );
        }

        this.props.updateUserObj({
          isGoogleCalendarSignInDone: res?.isGoogleCalendarSignInDone,
        });
        this.setState(
          {
            closings: res?.closings || [],
            closingsTotalCount: res?.totalCount || 0,
            googleEvents: res?.events || [],
            googleEventsTotalCount: res?.totalCountEvent || 0,
            dropdownValue,
            isGoogleCalendarSignInDone:
              res?.isGoogleCalendarSignInDone || false,
          },
          () => {
            this._applyFilterChange();
            this._manageLoading("showDataLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showDataLoading", false);
      });
  };

  // function change calendar view like month,week,day
  _onViewChange = (calendarView) => {
    this.setState({ calendarView });
  };

  // function to change range of the calendar
  _onRangeChange = (newDateRange, view) => {
    const { calendarView } = deepClone(this.state);

    let startDate, endDate;

    switch (view || calendarView) {
      case "day": {
        startDate = moment(newDateRange[0]).subtract(1, "days").endOf("day");
        endDate = moment(newDateRange[0]).endOf("day");
        break;
      }
      case "week": {
        startDate = moment(newDateRange[0]).subtract(1, "days").endOf("day");
        endDate = moment(newDateRange[6]).endOf("day");
        break;
      }
      case "month": {
        startDate = newDateRange.start;
        endDate = newDateRange.end;
        break;
      }
      case "agenda": {
        startDate = newDateRange.start;
        endDate = newDateRange.end;
        break;
      }
      default: {
        // yesterday - end of day Date
        startDate = moment().subtract(1, "days").endOf("day");
        // today - end of day Date
        endDate = moment().endOf("day");
        this._onViewChange("day");
        showToast("Something went wrong. Try again after sometime.", "error");
      }
    }

    this.setState(
      {
        dateRange: {
          startDate,
          endDate,
        },
      },
      () => {
        this._getAgentClosingMasterSchedule();
      }
    );
  };
  // select the event to go to the closing detials page
  _selectedEvent = (event) => {
    if (event?.closing?.id) {
      this.props.history.push(
        `/agent/closing-details-for-agents/${event.closing.id}`
      );
    } else if (event?.googleEvent?.htmlLink) {
      window.open(event.googleEvent.htmlLink, "_blank");
    }
  };
  // add calendar event but it is not used
  _addNewEvent = (e, slotInfo) => {
    var newEvents = this.state.events;
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end,
    });
    this.setState({
      alert: null,
      events: newEvents,
    });
  };

  _hideAlert = () => {
    this.setState({ alert: null });
  };
  // add new event
  _addNewEventAlert = (slotInfo) => {
    this.setState({
      alert: Swal.fire({
        title: "Event",
        icon: "danger",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, remove it!",
      })
        .then((e) => this._addNewEvent(e, slotInfo))
        .catch(() => {
          this._hideAlert();
        }),
    });
  };

  // for event color if agent is assigned then background color will be changed
  _eventColors = (event, start, end, isSelected) => {
    return {
      style: { backgroundColor: event.agent === "" ? "#EE8157" : "#6CD098" },
    };
  };
  // filter is handling from frontend this function is handling
  _applyFilterChange = () => {
    let { filters, closings, events, googleEvents } = deepClone(this.state);

    let isFilter = Object.keys(filters).find(
      (key) => filters[key] && filters[key].length
    );

    if (isFilter) {
      if (filters.client) {
        closings = closings.filter(
          (closing) =>
            closing?._signingCompany?._id === filters.client ||
            closing?._client?._id === filters.client ||
            closing?._assistant?._id === filters.client
        );
      }
      if (filters.location) {
        try {
          const stateObj = JSON.parse(filters.location);
          closings = closings.filter(
            (closing) =>
              closing.closingAddress?.state?.toLowerCase() ===
                stateObj.name?.toLowerCase() ||
              closing.closingAddress?.state?.toLowerCase() ===
                stateObj.abbreviation?.toLowerCase()
          );
        } catch (err) {}
      }
      if (filters.type) {
        closings = closings.filter(
          (closing) =>
            closing.loanType?.toLowerCase() === filters.type?.toLowerCase()
        );
      }
      if (filters.status) {
        closings = closings.filter(
          (closing) =>
            closing.status?.toLowerCase() === filters.status?.toLowerCase()
        );
      }
    }

    events = closings
      .map((closing) => {
        if (!closing?.appointmentDate || !closing?.closingAddress?.timeZone) {
          // Handle the case where appointmentDate or closingAddress.timeZone is undefined
          return null;
        }

        const startDateString = momenttz(closing.appointmentDate)
          .tz(closing.closingAddress.timeZone)
          .format("MM/DD/YYYY HH:mm:ss");

        const startDateIso = new Date(startDateString);
        const startDate = moment(startDateIso)._d;

        const endDateString = momenttz(closing.appointmentDate)
          .tz(closing.closingAddress.timeZone)
          .add(30, "minutes")
          .format("MM/DD/YYYY HH:mm:ss");

        const endDateIso = new Date(endDateString);
        const endDate = moment(endDateIso)._d;

        return {
          closing,
          title: closing._borrower[0]?.name?.full?.trim().length
            ? capitalize(closing._borrower[0].name.full.trim()) ||
              capitalize(closing._borrower[0].name.full.trim())
            : closing.closingNumber || "N/A",
          start: startDate,
          end: endDate,
          allDay: false,
        };
      })
      .filter((event) => event !== null);

    if (!isFilter && googleEvents?.length) {
      let formattedGoogleEvents = googleEvents.map((googleEvent) => {
        const { recurrence } = googleEvent;
        if (recurrence && recurrence.length) {
          // const rruleString = recurrence[0]?.trim();
          let skipDates = googleEvent?.skipDates || [];
          let rruleString = null;

          recurrence.forEach((recurrenceItem) => {
            if (recurrenceItem.startsWith("EXDATE")) {
              // Extract the EXDATE and convert it to UTC
              const exdateRaw = recurrenceItem.split(":")[1];
              const exdateUTC = moment
                .tz(exdateRaw, "Asia/Kolkata")
                .utc()
                .format("YYYY-MM-DD");
              skipDates.push(exdateUTC);
            } else if (recurrenceItem.startsWith("RRULE")) {
              rruleString = recurrenceItem.trim();
            }
          });
          const skipDateSet = new Set(
            skipDates?.map((date) => moment.utc(date).format("YYYY-MM-DD"))
          );
          const startDate = moment.utc(googleEvent.start);

          const endState = moment.utc(this.state.dateRange.endDate);
          var options = RRule.parseString(rruleString);
          options.dtstart = datetime(
            startDate.year(),
            startDate.month() + 1,
            startDate.date(),
            startDate.hour(),
            startDate.minute(),
            startDate.second()
          );

          if (options.until) {
            options.until = new Date(
              Date.UTC(
                options.until.getFullYear(),
                options.until.getMonth(),
                options.until.getDate(),
                options.until.getHours(),
                options.until.getMinutes(),
                options.until.getSeconds()
              )
            );
          } else {
            const endState = moment.utc(this.state.dateRange.endDate);
            options.until = datetime(
              endState.year(),
              endState.month() + 1,
              endState.date(),
              endState.hour(),
              endState.minute(),
              endState.second()
            );
          }

          // const skipDateSet = new Set(
          //   (googleEvent?.skipDates || [])?.map((date) =>
          //     moment.utc(date).format("YYYY-MM-DD")
          //   )
          // );

          const rule = new RRule(options);

          const filterDates = rule
            .all()
            .filter((date) => {
              const formattedDate = moment(date).format("YYYY-MM-DD");

              if (skipDateSet.has(formattedDate)) {
                return false;
              }
              if (this.state.calendarView === "day") {
                return moment(date).isSame(
                  moment(this.state.dateRange.endDate),
                  "day"
                );
              }
              return moment(date).isBetween(
                this.state.dateRange.startDate,
                this.state.dateRange.endDate,
                "day",
                "[]"
              );
            })
            .map((data) => {
              return {
                googleEvent,
                title: googleEvent.summary || googleEvent.eventId,
                start: new Date(data),
                end: moment(data)
                  .set({
                    hour: moment(googleEvent.end).hour(),
                    minute: moment(googleEvent.end).minute(),
                    second: moment(googleEvent.end).second(),
                  })
                  .toDate(),
                allDay: false,
              };
            });

          return filterDates;
        } else {
          return {
            googleEvent,
            title: googleEvent.summary || googleEvent.eventId,
            start: new Date(googleEvent.start),
            end: new Date(googleEvent.end),
            allDay: false,
          };
        }
      });

      events = [...events, ...formattedGoogleEvents.flat()];
    }

    this.setState({ events });
  };
  // function is used to handle filter on change
  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      this._applyFilterChange();
      this._persistFilter();
    });
  };
  // reset the filter
  _resetFilter = () => {
    this.setState(
      {
        filters: {
          client: "",
          status: "",
        },
      },
      () => {
        this._applyFilterChange();
        refreshFunc("agent-closing-master-schedule-responsive");

        deleteLocalStorageData("agentschedule");
      }
    );
  };
  // open google calendar sync modal
  _toggleGoogleCalendarSyncModal = (isOpenGoogleCalendarSyncModal = false) => {
    this.setState({ isOpenGoogleCalendarSyncModal });
  };

  componentDidMount = () => {
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("agent-closing-master-schedule");
    });
    HeaderEventEmitter.subscribe("calendar-sync", () => {
      console.log("calendar sync hit");
      this._getAgentClosingMasterSchedule();
    });
  };
  // used to sync google calendar
  _userGoogleCalendarSync = async () => {
    const { dateRange, calendarView } = deepClone(this.state);
    let middate;
    if (calendarView !== "month") {
      middate = new Date(
        (moment().endOf("month") + moment().startOf("month")) / 2
      );
    } else {
      middate = new Date(
        (dateRange.startDate.getTime() + dateRange.endDate.getTime()) / 2
      );
    }
    const payload = {
      endDate: moment(middate).endOf("month")._d,
      startDate: moment(middate).startOf("month").add(1, "days")._d,
    };
    try {
      await userGoogleCalendarSync(payload);
    } catch (error) {
      errorHandler(error);
    }
  };
  // download closing master schedule
  _downloadData = (action = "download") => {
    const { events, calendarView, filters } = deepClone(this.state);

    if (!events.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    let date = this._dateRange(calendarView);

    const payload = {
      userId: getLoggedInUserId(),
      action,
      startDate: formatOnlyDateMoment(date[0]),
      endDate: formatOnlyDateMoment(date[1]),
    };

    if (filters.status) payload["status"] = filters.status;
    if (filters.client) payload["clientId"] = filters.client;
    if (filters.agent) payload["agentId"] = filters.agent;

    const queryParams = structureQueryParams(payload);

    const apiUrl = `${BASE_URL}/agent/download/closing-master-schedule${queryParams}`;

    if (action === "download") {
      openUrlOnNewTab(apiUrl);
    } else if (action === "email") {
      this._manageLoading("isEmailLoading", true);
      makeGetRequest(apiUrl, true)
        .then((res) => {
          this._manageLoading("isEmailLoading", false);
          showToast("Email sent successfully.", "success");
        })
        .catch((error) => {
          this._manageLoading("isEmailLoading", false);
          errorHandler(error);
        });
    }
  };
  // open upgrade Accuount modal
  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };
  // function to save filter data in local storage
  _persistFilter = () => {
    const { filters } = this.state;
    if (filters && (filters.client || filters.status)) {
      localStorage.agentschedule = JSON.stringify(filters);
    } else {
      delete localStorage.agentschedule;
    }
  };

  // function to set dara from local storage to state
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentschedule) {
      try {
        const filters = JSON.parse(localStorage.agentschedule);
        this.setState({ filters }, () => {
          this._getAgentClosingMasterSchedule();
        });
      } catch (e) {
        this._getAgentClosingMasterSchedule();
      }
    } else {
      this._getAgentClosingMasterSchedule();
    }
  };

  // we are not using this function but this function is used to prepare the data for local storage if not present the key will be deleted

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);
    if (!filters.client) delete filters.client;
    if (!filters.status) delete filters.status;

    return filters;
  };

  render() {
    const {
      events,
      filters,
      loading,
      calendarView,
      dropdownValue,
      isOpenGoogleCalendarSyncModal,
      upgradeAccountModal,
      isClassAdded,
      isGoogleCalendarSignInDone,
    } = this.state;

    console.log("events >>", events);
    console.log(
      "isGoogleCalendarSignInDone>>>>>>>",
      isGoogleCalendarSignInDone,
      isProPlusUser()
    );

    return (
      <>
        <div className="content">
          {this.state.alert}
          <Row>
            <Col md="12">
              <div className="responsiveTitle">
                <h2>Master Schedule</h2>

                <div className="rightSide">
                  <Button color="link" onClick={this._resetFilter}>
                    <img
                      id="agent-closing-master-schedule-responsive"
                      src={require("../../../assets/img/refresh.svg").default}
                      alt="refresh"
                    />
                  </Button>
                  <Button
                    className="filterBarIcon"
                    color="link"
                    onClick={this.filterShowMobile}
                  >
                    <img
                      src={
                        require("../../../assets/img/filter_icon.svg").default
                      }
                      alt="Filter"
                    />
                  </Button>
                  <GoogleCalendarSyncModal
                    fetchData={this._getAgentClosingMasterSchedule}
                  />
                </div>
              </div>

              {/* filter for tablet and web */}
              <div
                onClick={this.filterShowMobile}
                className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
              ></div>
              <div
                className={`filterContainer responsiveFilter ${
                  isClassAdded ? "show" : ""
                }`}
              >
                <div className="filterIcon">
                  <img
                    src={require("../../../assets/img/filter_icon.svg").default}
                    alt="filter icon"
                  />
                  Filter by
                </div>
                <div className="mobileTitle">
                  <h2>Filter by</h2>
                  <Button
                    color="link"
                    className="closeButton"
                    onClick={this.filterShowMobile}
                  >
                    <img
                      src={
                        require("../../../assets/img/close_grey.svg").default
                      }
                      alt="close"
                      height={12}
                    />
                  </Button>
                </div>
                <div className="filterWrapper">
                  <FormGroup>
                    <Label>Client</Label>
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        name="client"
                        value={filters.client}
                        onChange={(e) =>
                          this._filterOnChange("client", e.target.value)
                        }
                      >
                        <option value="">All</option>
                        {dropdownValue?.clients?.length
                          ? dropdownValue.clients.map((client, index) =>
                              client ? (
                                <option key={index} value={client.value}>
                                  {capitalize(client.label)}
                                </option>
                              ) : null
                            )
                          : null}
                      </Input>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label>Status</Label>
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        name="status"
                        value={filters.status}
                        onChange={(e) =>
                          this._filterOnChange("status", e.target.value)
                        }
                      >
                        <option value="">All</option>
                        {agentClosingStatus.map((obj) => (
                          <option key={obj.value} value={obj.value}>
                            {obj.label}
                          </option>
                        ))}
                      </Input>
                    </div>
                  </FormGroup>

                  <Button
                    color="link"
                    outline
                    className="downloadButton ml-auto"
                    onClick={() => this._downloadData()}
                  >
                    Download
                    <img
                      src={require(`../../../assets/img/download_blue.png`)}
                      alt="download"
                    />
                  </Button>
                </div>
                <div className="clearButton">
                  <Button
                    size="md"
                    color="primary"
                    onClick={this.filterShowMobile}
                  >
                    Close
                  </Button>
                </div>
              </div>

              <div className="downloadButtonPos">
                <Button
                  color="link"
                  outline
                  onClick={() => this._downloadData()}
                >
                  Download
                  <img
                    src={require(`../../../assets/img/download_blue.png`)}
                    alt="download"
                  />
                </Button>
              </div>

              <div className="calendarWrap">
                <ReactBigCalendar
                  events={events}
                  calendarView={calendarView}
                  loading={loading.showDataLoading}
                  onNavigate={(date) => this.setState({ navigationDate: date })}
                  onView={(view) => this._onViewChange(view)}
                  onRangeChange={(dateRange, view) =>
                    this._onRangeChange(dateRange, view)
                  }
                  eventPropGetter={this._eventColors}
                  onSelectEvent={(event) => this._selectedEvent(event)}
                  // onSelectSlot={(slotInfo) => _addNewEventAlert(slotInfo)}
                />
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (payload) => dispatch(updateUserObj(payload)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClosingMasterScheduleAgent);
